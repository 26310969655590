import React from "react";

const FilterableScreen = (props) => (
  <div className="filterableScreen">
    {props.filters &&
      <div className="filterPagerHeader">{props.filters}</div>
    }
    <div className="contentWithFilters">{props.children}</div>
    {props.pager &&
      <div className="pagerFooter">{props.pager}</div>
    }
  </div>
);

export default FilterableScreen;