import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";

import termsOfServiceDocument from "../assets/terms_of_service/terms_of_service.txt";
import logo from "../assets/images/legacy_logo.png";
import { Navbar, NavbarBrand, Container } from "reactstrap";

export default function TermsAndConditions() {
  const [text, setText] = useState("");
  const location = useLocation();

  useEffect(() => {
    fetch(termsOfServiceDocument)
      .then((response) => response.text())
      .then((textContent) => {
        setText(textContent);
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setText("Failed to load terms and conditions.");
      });
  }, []);

  const backToSignInPath = _.includes(location.pathname, "/participant")
    ? "/signin"
    : "/login";

  return (
    <div>
      <Navbar className="my-2" color="dark" dark>
        <NavbarBrand href="/">
          <img
            alt="logo"
            src={logo}
            style={{
              height: 75,
              width: 75,
            }}
          />
          Legacy Personal Training
        </NavbarBrand>
      </Navbar>
      <Container style={{ maxWidth: "75%" }}>
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxWidth: "100%",
          }}
        >
          {text || "Loading..."}
        </pre>
      </Container>
      <div>
        <Link to={backToSignInPath}> Back to Sign In</Link>
      </div>
    </div>
  );
}
