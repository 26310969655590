import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Col,
  Button,
  Row,
  Input,
  ButtonGroup,
  Alert as Alert2
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { api, constants, UserContext, filterHelpers, helpers } from "../utils";
import { FilterSelect, Loader } from "../components";
import { DesktopContainer } from "../layouts";
import makeAnimated from "react-select/animated";
import { MdAddCircleOutline, MdDeleteForever } from "react-icons/md";

const mPlusIcon = (<MdAddCircleOutline className="" />);
const mTrash = (<MdDeleteForever className="" />);

const SELECT_STYLE_SETTINGS = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none"
    }),
    container: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none"
    }),
  };

const WORKOUT_SELECT_STYLE_SETTINGS = Object.assign({}, SELECT_STYLE_SETTINGS, {
    input: (provided, state) => ({
        ...provided,
        color: "blue"
      }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "blue"
      })
});

function ProgramSectionAndMatrix({
    category, 
    mtrxList, 
    mvmtList, 
    exGroups, 
    changeCategoryMovementCallback, 
    changeMatrixExerciseCallback, 
    changeHeaderCallback, 
    addCategoryCallback,
    removeCategoryCallback,
    addMovementCallback,
    removeMovementCallback,
    lastCategory}){
    return (<div key={`${category?.id}`}>
        <Row className="mx-1">
            <Col className={"program-cell blue-bg-white-txt workout-header-left-border-blue"}>
                <Input
                    value={category.category.header || ''}
                    onChange={(e) => {
                        changeHeaderCallback(category.category.seq, "header", e.target.value);
                    }}
                />
            </Col>
            <Col xs="1" className={"program-cell blue-bg-white-txt workout-header-right-border-blue"}>
                <ButtonGroup>
                    <Button
                        className="program-header-add"
                        style={{border: "white"}}
                        size="sm"
                        onClick={() => addCategoryCallback(category.category.seq)}
                    >
                        {mPlusIcon}
                    </Button>
                    <Button
                        color="danger"
                        size="sm"
                        onClick={() => removeCategoryCallback(category.category.seq)}
                    >
                        {mTrash}
                    </Button>
                </ButtonGroup>
            </Col>
            <Col key={"header-1"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
            <Col key={"header-2"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
            <Col key={"header-3"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
            <Col key={"header-4"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
            <Col key={"header-5"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
            <Col key={"header-6"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
            <Col key={"header-7"} className={"program-cell-header matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.header}</Col>
        </Row>
        <Row className="mx-1">
            <Col className={"program-cell blue-bg-white-txt workout-header-left-border-blue"}>
                <Input 
                    value={category.category.setsSubheader || ''}
                    onChange={(e) => {
                        changeHeaderCallback(category.category.seq, "setsSubheader", e.target.value);
                    }}
                />
            </Col>
            <Col xs="1" className={"program-cell blue-bg-white-txt workout-header-right-border-blue"}>&nbsp;</Col>
            <Col key={"subheader-1"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
            <Col key={"subheader-2"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
            <Col key={"subheader-3"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
            <Col key={"subheader-4"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
            <Col key={"subheader-5"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
            <Col key={"subheader-6"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
            <Col key={"subheader-7"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.setsSubheader}</Col>
        </Row>
        <Row className="mx-1">
            <Col className={"program-cell blue-bg-white-txt workout-header-left-border-blue"}>
                <Input 
                    value={category.category.repsSubheader || ''}
                    onChange={(e) => {
                        changeHeaderCallback(category.category.seq, "repsSubheader", e.target.value);
                    }}
                />
            </Col>
            <Col xs="1" className={"program-cell blue-bg-white-txt workout-header-right-border-blue"}>&nbsp;</Col>
            <Col key={"subheader-1"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
            <Col key={"subheader-2"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
            <Col key={"subheader-3"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
            <Col key={"subheader-4"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
            <Col key={"subheader-5"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
            <Col key={"subheader-6"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
            <Col key={"subheader-7"} className={"program-cell matrix-cell-l-margin blue-bg-white-txt workout-header-x-border"}>{category.category.repsSubheader}</Col>
        </Row>
        {_.map(category.movements, (m, mIdx) => {
            let matrix = _.find(mtrxList, mx => mx.movementId === m.movementId);
            let lastMovement = mIdx === category.movements.length - 1;
            if (!matrix) {
              matrix = mtrxList.length
                ? mtrxList[0]
                : null;
            }
            return (
                <Row key={`${m.seq}-${m.movementId}`} className="mx-1">
                    <Col className={`program-cell workout-header-left-border-blue ${lastCategory && lastMovement ? "workout-header-bottom-border-blue" : ""}`} style={{fontWeight: "normal"}}>
                        <Select
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={mvmtList ? mvmtList : []}
                            onChange={(mObj) => changeCategoryMovementCallback(category.category.seq, m.seq, mObj)}
                            value={_.find(mvmtList, mvmt => mvmt.value === m.movementId)}
                            styles={WORKOUT_SELECT_STYLE_SETTINGS}
                        />
                    </Col>
                    <Col xs="1" className={`program-cell workout-header-right-border-blue ${lastCategory && lastMovement ? "workout-header-bottom-border-blue" : ""}`} style={{fontWeight: "normal"}}>
                        <ButtonGroup>
                            <Button
                                color="info"
                                size="sm"
                                onClick={() => addMovementCallback(category.category.seq, m.seq)}
                            >
                                {mPlusIcon}
                            </Button>
                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => removeMovementCallback(category.category.seq, m.seq)}
                            >
                                {mTrash}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    
                    {/* Map through matrix difficulties */}
                    {matrix && _.map(matrix.sortedPieces, (piece, pIdx) => {
                        const exerciseList = exGroups[m.movementId];
                        return (
                          <Col key={`piece-${piece.phaseId}-${piece.movementId}-${piece.difficultyId}`}
                            className={`matrix-cell matrix-cell-l-margin workout-header-x-border ${lastCategory && lastMovement ? "workout-header-bottom-border-blue" : ""}`}>
                            <Select
                              closeMenuOnSelect
                              components={makeAnimated()}
                              options={exerciseList ? exerciseList : []}
                              onChange={(eObj) => changeMatrixExerciseCallback(matrix, eObj, pIdx)}
                              value={_.find(exerciseList, ex => ex.value === piece.exerciseId)}
                              styles={SELECT_STYLE_SETTINGS}
                            />
                          </Col>
                        )
                    })}
                </Row>
            );
        })}
    </div>);
}

export default function ProgramMovementMatrix(props){
    const [filters, setFilters] = useState([]);
    const [filterDisplayText, setFilterDisplayText] = useState("");
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [orgsList, setOrgsList] = useState([]);
    const [phaseList, setPhaseList] = useState([]);
    const [periodList, setPeriodList] = useState([]);
    const [workoutList, setWorkoutList] = useState([]);
    const [movementList, setMovementList] = useState([]);
    const [exerciseGroupedList, setExerciseGroupedList] = useState([]);
    const [program, setProgram] = useState(null);
    const [matrixList, setMatrixList] = useState(null);
    const [messages, setMessages] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetchNewMatrix, setFetchNewMatrix] = useState(false);

    const userCtx = useContext(UserContext);
    const isSysAdmin = _.includes(userCtx.currentUser.roleTypeIdList, constants.ROLE_IDS.SYSADMIN);

    useEffect(() => {
        LoadOrgs();
    }, []);

    useEffect(()=>{
        if(!selectedOrg) return;
        GetPageLists();
    }, [selectedOrg]);

    useEffect(()=> {
        if(messages){
            setTimeout(() => {
              setMessages([]);
            }, 15000);
        }        
    }, [messages]);

    useEffect(()=>{
        if (!program || !loadedOnce || !fetchNewMatrix) 
          return;
        GetMatrixData();
    }, [fetchNewMatrix]);

    useEffect(() => {
        let filterDisplay = "";
        if (!filters || !filters.length || filters.length === 0) {
          return filterDisplay;
        }
        filterDisplay = _.chain(filters)
          .map((x) => {
            return x.value !== null || x.values || x.startDate || x.endDate
              ? x.filteredDisplay
              : null;
          })
          .filter((x) => x !== null && x !== undefined)
          .value()
          .join(" | ");
        setFilterDisplayText(filterDisplay !== "" ? ` - ${filterDisplay}` : "");
      }, [filters]);

    function GetPageLists(){
        api.fetch(`WorkoutAdmin/GetProgramMovementMatrixLists/${selectedOrg.value}`)
        .then((response) => {
            if(response.data && response.data.success) {
                let vm = response.data.message;
                if(vm.phases) {
                    let phases = helpers.idNameToValueLabel(vm.phases);
                    setPhaseList(phases);
                }
                if(vm.periods) {
                    let periods = helpers.idNameToValueLabel(vm.periods);
                    setPeriodList(periods);
                }
                if(vm.workouts) {
                    let workouts = helpers.idNameToValueLabel(vm.workouts);
                    setWorkoutList(workouts);
                }
                if(vm.movements) {
                    let movements = helpers.idNameToValueLabel(vm.movements);
                    movements = _.orderBy(movements, ['label'], ['asc']);
                    setMovementList(movements);
                }
                if(vm.exerciseGroups) {
                    let copy = Object.assign({}, vm.exerciseGroups);
                    for (const property in vm.exerciseGroups) {
                         let mappedProperty = helpers.idNameToValueLabel(vm.exerciseGroups[property]);
                         mappedProperty = _.orderBy(mappedProperty, ['label'], ['asc']);
                         copy[property] = mappedProperty;
                    }
                    setExerciseGroupedList(copy);
                }
            }
        })
        .catch(api.catchHandler);   
    }

    function LoadOrgs () {
        api
        .post(`OrgAdmin/GetOrgSimpleList`, {
        ActiveOnly: true,
        })
        .then((r) => {
            if (r.data && r.data.success) {
                let result = _.map(r.data.message, (x) => {
                    return {
                        label: x.name,
                        value: x.id,
                    };
                });
                if (!isSysAdmin) {
                    result = _.filter(
                        result,
                        (x) => x.value === userCtx.currentUser.orgId
                    );
                }
                setOrgsList(result);
                setSelectedOrg(_.find(result, x => x.value === userCtx.currentUser.orgId));
            }
        })
        .catch(api.catchHandler);
    };

    function IsValid(){
        let errors = [];
        if(!filterHelpers.currentFilterValue(filters, "PhaseId")){
            errors.push("Missing selected phase.");
        }
        if(!filterHelpers.currentFilterValue(filters, "PeriodId")){
            errors.push("Missing selected period.");
        }
        if(!filterHelpers.currentFilterValue(filters, "OrgWorkoutId")){
            errors.push("Missing selected workout.");
        }
        if(!selectedOrg || !selectedOrg.value){
            errors.push("Missing selected Organization.");
        }
        if(errors.length){
            let errorObjs = _.map(errors, msg => {
                return {
                    flavor: "danger",
                    text: msg
                };
            });
            setMessages(errorObjs);
            return false;
        }
        return true;
    }

    function CreateProgramCriteriaPayload(){
        return {
            ActiveOnly: true,
            OrgId: selectedOrg.value,
            PhaseId: filterHelpers.currentFilterValue(filters, "PhaseId").value,
            PeriodId: filterHelpers.currentFilterValue(filters, "PeriodId").value,
            OrgWorkoutId: filterHelpers.currentFilterValue(filters,"OrgWorkoutId").value
        };
    }

    function GetProgramMovementMatrixData(){
      if (!IsValid()) return;
      clearProgramAndMatrix();
      let payload = CreateProgramCriteriaPayload();
      api.post(`WorkoutAdmin/GetProgramAndMovementMatrix`, payload).then((r) => {
        if (r.data && r.data.success) {
          setMatrixList(r.data.message.movementMatrixList);
          setProgram(r.data.message.programBuilderVM);
          setLoadedOnce(true);
        }
        else
        {
          setMessages([{ flavor: "danger", text: r.data.message }]);
        }
      }).catch(api.catchHandler);
    }

    function GetMatrixData() {
        let movementIdList = [];
        console.log('p', program)
        _.forEach(program.categories, (cat) => {
          let movementIds = _.map(cat.movements, move => move.movementId);
          movementIdList = movementIdList.concat(movementIds);
        })
        setLoading(true)
        const payload = {
          OrgId: selectedOrg.value,
          PhaseId: filterHelpers.currentFilterValue(filters, "PhaseId").value,
          ActiveOnly: true,
          PeriodId: program.orgWorkout.periodId,
          OrgWorkoutId: program.orgWorkout.orgWorkoutId,
          OverrideMovementIds: _.uniq(movementIdList)
        };
        api.post("WorkoutAdmin/GetMatrixList2", payload).then((response) => {
          if(response.data && response.data.success){
            setMatrixList(response.data.message);
          }
        })
        .catch(api.catchHandler)
        .finally(() => {
            setLoading(false);
            setFetchNewMatrix(false);
        });
    }

    function CreateSavePayload() {
      return {
        ProgramBuilderVM: program,
        MovementMatrixList: matrixList
      };
    }

    function SaveProgramMovementMatrixData(){
      if (isSaving) return;
      setLoading(true);
      setIsSaving(true);
      api.post("WorkoutAdmin/SaveProgramAndMovementMatrixData", CreateSavePayload()).then((response) => {
        if (response.data && response.data.success) {
          setMessages([{
            flavor: "success",
            text: "Changes were saved successfully"
          }]);
          //clearFilterSet();
          GetProgramMovementMatrixData();
        }
      }).catch(api.catchHandler)
      .finally(() => {
        setIsSaving(false);
        setLoading(false);
      });
    }

    function clearFilterSet() {
        setFilterDisplayText("");
        setFilters([]);
        clearProgramAndMatrix();
    }

    function resetFilters(){
        setFilters([
            { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
        ]);
    }

    function onFilterChange(changedFilter) {
        const filterChanges = filterHelpers.getFilters(filters, changedFilter);
        setFilters((prev) => {
            if (prev) {
            prev = filterChanges;
            }
            return prev;
        });
    }

    function clearProgramAndMatrix(){
        setProgram(null);
        setMatrixList(null);
        setDataChanged(false);
    }

    function clearFilterOptions(){
      setPhaseList([]);
      setPeriodList([]);
      setWorkoutList([]);
    }

    function onCategoryMovementChange(categorySequence, movementSequence, movementSelect){
      setLoading(true);
      let programCopy = Object.assign({}, program);
      programCopy.categories[categorySequence - 1].movements[movementSequence - 1].movementId = movementSelect.value;
      programCopy.categories[categorySequence - 1].movements[movementSequence - 1].name = movementSelect.label;
      setMatrixList(null);
      setProgram(programCopy);
      setFetchNewMatrix(true);
      setDataChanged(true);
      setLoading(false);
    }

    function onChangeMatrixExercise(matrix, exerciseSelect, pieceIndex) {
        let copy = JSON.parse(JSON.stringify(matrixList));
        matrix.sortedPieces[pieceIndex].exerciseId = exerciseSelect.value;
        let matrixIndex = _.findIndex(copy, mtx => (
            mtx.movementId === matrix.movementId 
            && mtx.phaseId === matrix.phaseId
            && mtx.orgWorkoutCategoryId === matrix.orgWorkoutCategoryId));
        copy[matrixIndex] = matrix;
        setMatrixList(copy);
        setDataChanged(true);
    }

    function onCategoryHeaderChange(categorySequence, typeOfHeader, value){
        console.log("categorySequence", categorySequence)
        console.log("typeOfHeader", typeOfHeader)
        console.log("value", value)
        if(!value || !value.length) value = null;
        let programCopy = Object.assign({}, program);
        console.log("header current value", programCopy.categories[categorySequence - 1].category[typeOfHeader])
        programCopy.categories[categorySequence - 1].category[typeOfHeader] = value;
        setProgram(programCopy);
        setDataChanged(true);
    }

    function addCategory(categorySequence) {
        let categoryIndexToCopy = categorySequence - 1;
        let newcategoryInsertIndex = categorySequence;
        let programCopy = Object.assign({}, program);
        let categoryCopy = JSON.parse(JSON.stringify(programCopy.categories[categoryIndexToCopy]));
        programCopy.categories.splice(categoryIndexToCopy + 1, 0, categoryCopy);
        programCopy.categories = adjustCategorySequences(programCopy.categories);
        let newSeq = programCopy.categories[newcategoryInsertIndex].category.seq;
        programCopy.categories[newcategoryInsertIndex].category.header = `Header ${newSeq}`;
        programCopy.categories[newcategoryInsertIndex].category.subHeader = `Sub Header ${newSeq}`;
        console.log("new program", programCopy);
        setProgram(programCopy);
        setFetchNewMatrix(true);
        setDataChanged(true);
    }

    function removeCategory(categorySequence) {
        let categoryIndex = categorySequence - 1;
        let programCopy = Object.assign({}, program);
        programCopy.categories.splice(categoryIndex, 1);
        programCopy.categories = adjustCategorySequences(programCopy.categories);
        setProgram(programCopy);
        setFetchNewMatrix(true);
        setDataChanged(true);
    }

    function adjustCategorySequences(categories) {
        if(!categories || !categories.length) return categories;
        let counter = 1;
        for( let i = 0; i < categories.length; i ++) {
            counter = i + 1;
            categories[i].category.seq = counter;
        }
        return categories;
    }

    function addMovementToCategory(categorySequence, movementSequence) {
        let categoryIndexToCopy = categorySequence - 1;
        let movementIndexToCopy = movementSequence - 1;
        let programCopy = Object.assign({}, program);        
        let movementCopy = JSON.parse(JSON.stringify(programCopy.categories[categoryIndexToCopy].movements[movementIndexToCopy]));
        programCopy.categories[categoryIndexToCopy].movements.splice(movementIndexToCopy + 1, 0, movementCopy);
        programCopy.categories[categoryIndexToCopy].movements = adjustMovementSequences(programCopy.categories[categoryIndexToCopy].movements);
        setMatrixList(null);
        setProgram(programCopy);
        setFetchNewMatrix(true);
        setDataChanged(true);
    }

    function removeMovementfromCategory(categorySequence, movementSequence) {
        let categoryIndexToCopy = categorySequence - 1;
        let movementIndexToCopy = movementSequence - 1;
        let programCopy = Object.assign({}, program);
        programCopy.categories[categoryIndexToCopy].movements.splice(movementIndexToCopy, 1);
        programCopy.categories[categoryIndexToCopy].movements = adjustMovementSequences(programCopy.categories[categoryIndexToCopy].movements);
        setMatrixList(null);
        setProgram(programCopy);
        setFetchNewMatrix(true);
        setDataChanged(true);
    }

    function adjustMovementSequences(movements) {
        if(!movements || !movements.length) return movements;
        let counter = 1;
        for( let i = 0; i < movements.length; i++) {
            counter = i + 1;
            movements[i].seq = counter;
        }
        return movements;
    }

    return (
        <Fragment>
            <DesktopContainer screenName="PROGRAMMING" textColor="blue-txt">
                {messages && messages.length
                    ? (<Row>
                        <Col xs="4">
                            {_.map(messages, (msg, index) => {
                                return (
                                    <Alert2
                                    key={`A-${index}`}
                                    className={msg.flavor}
                                    style={{ marginTop: "15px", borderRadius: "15px" }}
                                    fade
                                    >
                                        {msg.text}
                                    </Alert2>
                                )
                            })}
                        </Col>
                    </Row>)
                    : null
                }
                <Row>
                    <Col sm="3">
                        <Select
                            isMulti={false}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={orgsList ? orgsList : []}
                            onChange={(org) => {
                                setSelectedOrg(org);
                                resetFilters();
                                clearFilterOptions();
                                clearProgramAndMatrix();
                            }}
                            isClearable={true}
                            value={selectedOrg}
                            onBlurResetsInput={false}
                            onSelectResetsInput={false}
                            onCloseResetsInput={false}
                            classNamePrefix="react-select"
                        />
                    </Col>
                </Row>
                {selectedOrg
                    ?   (<>
                            <Row>
                                <Col xs="12">
                                <span title="Toggle filter display" className="filter m-1">
                                    <FontAwesomeIcon icon="filter" /> Filters
                                </span>
                                <span
                                    className="filter-display m-1"
                                    title={filterDisplayText}
                                >
                                    {filterDisplayText}
                                </span>
                                {filters && filters.length !== 0 && (
                                    <Button
                                    className="filter-display  m-1 ml-4"
                                    size="sm"
                                    onClick={clearFilterSet}
                                    >
                                    <FontAwesomeIcon
                                        icon="backspace"
                                        // className="mr-2 ml-0 mt-0 mb-0"
                                    />{" "}
                                    Clear
                                    </Button>
                                )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col sm="3">
                                            <FilterSelect
                                                filterName="PhaseId"
                                                displayName="Phase"
                                                value={filterHelpers.currentFilterValue(
                                                filters,
                                                "PhaseId"
                                                )}
                                                onChangeCallback={(e) => {
                                                    onFilterChange(e);
                                                    clearProgramAndMatrix();
                                                }}
                                                options={phaseList ? phaseList : []}
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <FilterSelect
                                                filterName="PeriodId"
                                                displayName="Period"
                                                value={filterHelpers.currentFilterValue(
                                                filters,
                                                "PeriodId"
                                                )}
                                                onChangeCallback={(e) => {
                                                    onFilterChange(e);
                                                    clearProgramAndMatrix();
                                                }}
                                                options={periodList ? periodList : []}
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <FilterSelect
                                                filterName="OrgWorkoutId"
                                                displayName="Workout"
                                                value={filterHelpers.currentFilterValue(
                                                filters,
                                                "OrgWorkoutId"
                                                )}
                                                onChangeCallback={(e) => {
                                                    onFilterChange(e);
                                                    clearProgramAndMatrix();
                                                }}
                                                options={workoutList ? workoutList : []}
                                            />
                                        </Col>
                                        <Col sm="3" style={{verticalAlign: "bottom"}}>
                                            <Button
                                                className={"program-matrix-get"}
                                                onClick={GetProgramMovementMatrixData}
                                                disabled={messages && messages.length > 0}
                                            >
                                                Get Workout
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>)
                    : null}
                {loading
                  ? (<Loader />)
                  : null
                }
                {!loading && program && matrixList && matrixList.length
                    ?   (<>
                            <Row className="mx-1">
                                {/* <Col className="program-matrix-header program-cell">&nbsp;</Col>
                                <Col className="program-matrix-header matrix-cell matrix-header">&nbsp;</Col>
                                <Col className="program-matrix-header matrix-cell matrix-header">&nbsp;</Col>
                                <Col className="program-matrix-header matrix-cell matrix-header">&nbsp;</Col> */}
                                <Col className="program-matrix-header movement-matrix-borders matrix-header program-matrix-top-border blue-bg-white-txt workout-header-bottom-border" >Movement Matrix</Col>
                                {/* <Col className="program-matrix-header matrix-cell matrix-header">&nbsp;</Col>
                                <Col className="program-matrix-header matrix-cell matrix-header">&nbsp;</Col>
                                <Col className="program-matrix-header matrix-cell matrix-header">&nbsp;</Col> */}
                            </Row>
                            <Row className="mx-1">
                                <Col className="program-matrix-header white-bg workout-header-left-border-blue workout-header-bottom-border">Workout</Col>
                                <Col xs="1" className="workout-header-bottom-border white-bg workout-header-right-border-blue">&nbsp;</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">-3</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">-2</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">-1</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">Base</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">1</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">2</Col>
                                <Col className="program-matrix-header white-bg matrix-header workout-header-x-border workout-header-bottom-border matrix-cell-l-margin">3</Col>
                            </Row>
                            {_.map(program.categories, (pc, index) => {
                                let lastCategory = index === program.categories.length - 1;
                                return (<ProgramSectionAndMatrix 
                                    key={`program-cat-${index}`}
                                    category={pc}
                                    mtrxList={matrixList}
                                    mvmtList={movementList}
                                    exGroups={exerciseGroupedList}
                                    changeCategoryMovementCallback={onCategoryMovementChange}
                                    changeMatrixExerciseCallback={onChangeMatrixExercise}
                                    changeHeaderCallback={onCategoryHeaderChange}
                                    addCategoryCallback={addCategory}
                                    removeCategoryCallback={removeCategory}
                                    addMovementCallback={addMovementToCategory}
                                    removeMovementCallback={removeMovementfromCategory}
                                    lastCategory={lastCategory}
                                />);
                            })}
                            <Row className="m-1">
                                <Col className="p-0">
                                    <Button
                                      className="program-matrix-save"
                                      onClick={SaveProgramMovementMatrixData}
                                      disabled={!dataChanged}
                                    >
                                      Save
                                    </Button>
                                </Col>
                            </Row>
                        </>)
                    : null}
            </DesktopContainer>
        </Fragment>
    )
}