import React, { useEffect, useState } from 'react';
import {
  Alert as RSAlert,
  Button,
  Card,
  CardBody,
  Col,
  Row
} from 'reactstrap';
import Alert from "react-s-alert";
import Select from 'react-select';
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import Papa from "papaparse";
import { api, dateHelpers, helpers } from '../utils';

const CSV_TYPES = {
  duplicate: 0,
  bad: 1
};

export default function ClientUpload(props) {
  const [orgList, setOrgList] = useState([]);
  const [working, setWorking] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [uploadCount, setUploadCount] = useState(0);
  const [badData, setBadData] = useState([]);
  const [duplicateClients, setDuplicateClients] = useState([]);

  useEffect(() => {
    api
      .post("OrgAdmin/GetOrgList", {})
      .then(r => {
        if (r.data.success) {
          setOrgList(_.map(r.data.message, org => ({ label: org.name, value: org.id })))
        } else {
          Alert.error("Error loading Org List");
          console.error(r.data.message);
        }
      })
      .catch(api.catchHandler)
  }, [])

  function onDrop(newFiles, badFiles) {
    setFiles(_.concat(files, newFiles))
    if (badFiles.length) {
      Alert.error("Only .CSV files are accepted!");
    }
  }

  function upload() {
    if (working) return;
    setWorking(true);
    const payload = new FormData();
    _.forEach(files, file => {
      payload.append('file', file);
    })
    api
      .post_form_data(`OrgAdmin/UploadCSV/${selectedOrg}`, payload)
      .then(r => {
        if (r.data.success) {
          setFiles([]);
          setUploadCount(r.data.uploadedCount);
          setBadData(r.data.badData);
          setDuplicateClients(r.data.duplicateClients);
        } else {
          Alert.error(r.data.message);
          console.error(r.data.message);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setWorking(false))
  }

  function getCSV(csvType) {
    const [data, fileName] = csvType === CSV_TYPES.duplicate
      ? [duplicateClients, "DuplicateClients.csv"]
      : [badData, "BadData.csv"]
    _.forEach(data, datum => {
      datum.dob = dateHelpers.formatDateForServer(datum.dob)
    })
    helpers.browserExportCSVFile(Papa.unparse(data, { type: 'text/csv;charset=utf-8;' }), fileName)
  }

  return (
    <Card className="w-75 mx-auto mt-5">
      <CardBody>
        <Row className="text-center">
          <Col>
            Select an Organization to Upload to <span className="text-muted font-italic">(Required)</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Select
              options={orgList}
              value={_.find(orgList, org => org.value === selectedOrg)}
              onChange={opt => setSelectedOrg(opt.value)}
            />
          </Col>
        </Row>
        <Row className="text-center mb-3">
          <Col xs={12}>
            .CSV file needs to begin with the following headers:<br />
            FirstName, LastName, DOB, ClientNotes, Contraindications, TrainingNotes, TrainingProgram, PrimaryGoal, TrainingFrequency, IntensityLevel
          </Col>
          <Col className="text-muted font-italic">
            DOB should be in mm-dd-yyyy format
          </Col>
        </Row>
        <Dropzone
          onDrop={onDrop}
          accept=".csv"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="text-center border rounded-lg pt-3 mb-3">
              <input {...getInputProps()} />
              <div>Drop a .csv here, or click to search computer</div>
              <FontAwesomeIcon icon="file-upload" size="3x" className="my-4" />
              <Row className={files.length ? "" : "d-none"}>
                <Col className="font-weight-bold">File Name</Col>
              </Row>
              {_.map(files, (file, idx) => {
                return (
                  <div key={idx} onClick={e => e.stopPropagation()} className={`${idx % 2 === 0 ? "bg-white" : "bg-light"}`}>
                    <span className="font-italic">
                      {file.name}
                    </span>
                  </div>
                )
              })}
            </div>
          )}
        </Dropzone>
        <Row className="mb-3">
          <Col xs={{ offset: 3, size: 2 }}>
            <Button
              onClick={() => {
                setFiles([]);
                setSelectedOrg(null);
                setUploadCount(0);
                setBadData([]);
                setDuplicateClients([]);
              }}
              color="secondary"
              className="w-100"
              disabled={working || selectedOrg === null || !files.length}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={{ offset: 2, size: 2 }}>
            <Button onClick={() => upload()} color="success" className="w-100" disabled={working || selectedOrg === null || !files.length}>
              Upload
            </Button>
          </Col>
        </Row>
        <div className="text-center">
          <RSAlert color="success" className={uploadCount === 0 ? "mb-2 d-none" : ""}>
            {`Successfully uploaded ${uploadCount} client(s)!`}
          </RSAlert>
          <Row>
            <Col xs={6}>
              <RSAlert color="danger" className={duplicateClients.length ? "" : "mb-2 d-none"}>
                <div>{duplicateClients.length} duplicate client(s) were detected.<br />Click to download a .CSV of these clients.</div>
                <div>
                  <Button className="mt-2" onClick={() => getCSV(CSV_TYPES.duplicate)} color="light">
                    Download Duplicate Clients
                  </Button>
                </div>
              </RSAlert>
            </Col>
            <Col xs={6}>
              <RSAlert color="danger" className={badData.length ? "" : "d-none"}>
                <div>{badData.length} client(s) with missing or incorrect data were detected. <br />Click to download a .CSV of these clients.</div>
                <div>
                  <Button className="mt-2" onClick={() => getCSV(CSV_TYPES.bad)} color="light">
                    Download Clients with Bad Data
                  </Button>
                </div>
              </RSAlert>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card >
  );
}