import React from "react";

export default function Loader(_props) {
  return (
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
  );
}
