import React from "react";
import { Row } from "reactstrap";
import _ from "lodash";
import { constants } from "../utils";
import { LinkPanel } from "../components";
import { DesktopContainer } from "../layouts";

export default function Admin(_props) {
  // const userCtx = useContext(UserContext);
  return (
    <DesktopContainer screenName={"Administration"}>
      <Row className="mt-2 mb-2">
        <LinkPanel
          key='link-users'
          colSize="4"
          iconKey="user"
          title="Users"
          routePath="/admin/users"
        />
        {_.map(constants.REFERENCE_DATA_URL_LIST, (dt) => (
          <LinkPanel
            key={`link-${dt.pageTitle}`}
            colSize="4"
            iconKey="tags"
            title={dt.pageTitle}
            routePath={dt.reactPath}
            data={dt}
          />
        ))}
      </Row>
    </DesktopContainer>
  );
}
